import axios from 'axios'
import nprogress from 'nprogress'

const WS_URL_PROD = "https://ws-app.suitpay.app/api/v1"
const WS_URL_HOM = "https://sandbox.ws.suitpay.app/api/v1"
const WS_URL_DEV = "http://localhost:8080/api/v1"

const KYC_CERBERUS_PROD = "https://sdk.idcerberus.com"
const KYC_CERBERUS_HOM = "https://sdk-hml.idcerberus.com"

let progressCallCount = 0;

function getWsUrl() {
  if (window.location.href.includes('suitpay.app') && !window.location.href.includes('sandbox.web.suitpay.app')) {
    return WS_URL_PROD
  } else if (window.location.href.includes('sandbox.web.suitpay.app')) {
    return WS_URL_HOM
  }
  return WS_URL_DEV
}

function setLocalStorage(response) {
  localStorage.setItem('ut', response.headers['ut'])
  localStorage.setItem('urt', response.headers['urt'])
  localStorage.setItem('name', response.data.name)
  localStorage.setItem('username', response.data.username)
  localStorage.setItem('usernameDisplay', response.data.usernameDisplay)
  localStorage.setItem('email', response.data.email)
  localStorage.setItem('personType', response.data.personType)
  localStorage.setItem('docsOk', response.data.docsOk)
  localStorage.setItem('verifCode', response.data.verifCode)
  localStorage.setItem('roles', JSON.stringify(response.data.roles))
  localStorage.setItem('transactionAuthorizationEmail', response.data.transactionAuthorizationEmail)
  localStorage.setItem('transactionAuthorizationTotp', response.data.transactionAuthorizationTotp)
  localStorage.setItem('avatarBase64', response.data.avatarBase64)
  localStorage.setItem('avatarFileType', response.data.avatarFileType)
  localStorage.setItem('approvedAccount', response.data.approvedAccount)
  localStorage.setItem('suitJourneyAvailableToRequest', JSON.stringify(response.data.suitJourneyAvailableToRequest))
  localStorage.setItem('firstTimeOpeningOverview', true)
}

export function signin(username, password, recaptchaToken, verifCode, handlerThen, handlerCatch) {
  postWs("/auth/signin",
    false,
    null,
    {username: username, password: password, recaptchaToken: recaptchaToken, verifCode: verifCode},
    function (response) {
      setLocalStorage(response)
      handlerThen(response)
    },
    function (error) {
      localStorage.clear()
      handlerCatch(error)
    })
}

export function signup(username, password, email, recaptchaToken, handlerThen, handlerCatch, codeRecommendation, phone, name, companyName, document, nicheMarket, anotherNicheMarket, websiteUrl, revenue, referralSource, sourceContact, countryCode, utmSource, utmTerm, utmCampaign, utmContent, utmMedium) {
  postWs("/auth/signup",
    false,
    null,
    {username: username, password: password, email: email, recaptchaToken: recaptchaToken, codeRecommendation: codeRecommendation, phone: phone, name: name, companyName: companyName,  document: document, nicheMarket: nicheMarket, anotherNicheMarket: anotherNicheMarket, websiteUrl: websiteUrl, revenue: revenue, referralSource: referralSource, sourceContact: sourceContact, utmSource: utmSource, utmTerm: utmTerm, utmCampaign: utmCampaign, utmContent: utmContent, utmMedium: utmMedium, countryCode: countryCode},
    handlerThen,
    handlerCatch
  )
}

export function getKycUrl() {
  if (window.location.href.includes('suitpay.app') && !window.location.href.includes('sandbox.web.suitpay')) {
    return KYC_CERBERUS_PROD
  } else if (window.location.href.includes('sandbox.web.suitpay')) {
    return KYC_CERBERUS_HOM
  }
  return KYC_CERBERUS_HOM
}

export function failWs(error) {
  localStorage.clear()
  location.href = "/login?fail=1"
  if (error) {
    console.error(error)
  }
}

function refresh(path, method, isAuthenticated, params, body, handlerThen, handlerCatch) {
  axios({
    url: getWsUrl() + "/auth/refresh-token",
    method: "GET",
    headers: {
      urt: localStorage.getItem('urt')
    }
  }).then(response => {
    setLocalStorage(response)
    callWs(path, method, null, isAuthenticated, params, body, handlerThen, handlerCatch)
  }).catch(error => {
    localStorage.clear()
    location.reload()
  })
}

export function getWs(path, isAuthenticated, params, handlerThen, handlerCatch) {
  callWs(path, "GET", null, isAuthenticated, params, null, handlerThen, handlerCatch)
}

export function postWs(path, isAuthenticated, params, body, handlerThen, handlerCatch) {
  callWs(path, "POST", null, isAuthenticated, params, body, handlerThen, handlerCatch)
}

export function callWs(path, method, headers, isAuthenticated, params, body, handlerThen, handlerCatch) {
  nprogress.start()
  progressCallCount++
  let allHeaders = {}
  if (isAuthenticated) {
    allHeaders['ut'] = localStorage.getItem('ut')
  }
  if (headers) {
    Object.assign(allHeaders, headers)
  }
  axios({
    url: getWsUrl() + path,
    method: method,
    headers: allHeaders,
    params: params,
    data: body
  }).then(response =>
    handlerThen(response)
  ).catch(error => {
    try {
      if (isAuthenticated) {
        let expired
        try {
          expired = 'EXPIRED' == error.response.data.message
        } catch (err) {
          expired = false
        }
        if (expired) {
          refresh(path, method, isAuthenticated, params, body, handlerThen, handlerCatch)
          return
        }
      }
      handlerCatch(error)
    } catch (err2) {
      failWs(error)
    }
  }).finally(function () {
      progressCallCount--
      if(progressCallCount <= 0){
        // console.log(progressCallCount)
        progressCallCount = 0
        nprogress.done()
      }
    }
  )
}
